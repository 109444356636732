import type DataGridControl from './DataGridControl.ts';
import type DataColumn from './DataGrid.DataColumn.ts';

import { onMounted, onBeforeUnmount, inject, ref, computed } from 'vue';
import { InjectionKeys } from 'o365-utils';
import { useVirtualScroll2 } from 'o365-vue-utils';

declare module './DataGridControl.ts' {
    interface DataGridControl {
        _virtualColumnsUid?: string;
    }
}

const scopeMap = new Map<string, object>();

export default function useVirtualColumns(pDataGridControl: { value: DataGridControl }) {
    if (pDataGridControl) {
        const scope = (function () {
            const container = ref<HTMLElement>();
            function updateContainer() {
                container.value = pDataGridControl.value.viewPort;
            }
            onMounted(() => {
                updateContainer();
            });
            const { scrollData, handleScroll, currentPosition
            } = useVirtualScroll2<DataColumn>({
                data: computed(() => pDataGridControl.value.dataColumns.centerColumns.filter(col => !col.hide)),
                container: container,
                getItemSize: item => item.width,
                buffer: 8,
                // proxifyItems: true,
                // itemSize: +props.cardWidth,
                // watchTarget: () => watchTarget.value,
                // noStartBuffer: true,
                horizontal: true,
            });


            onBeforeUnmount(() => {

            });
            window.rrr = { scrollData, handleScroll, currentPosition };

            return { scrollData, handleScroll, currentPosition };
        })();

        pDataGridControl.value._virtualColumnsUid = crypto.randomUUID();

        scopeMap.set(pDataGridControl.value._virtualColumnsUid, scope);
        return scope;
    } else {
        const dataGridControl = inject(InjectionKeys.dataGridControlKey) as { value: DataGridControl };
        if (dataGridControl.value._virtualColumnsUid == null) {
            throw new Error('Could not resolve virtual column scope');
        }
        return scopeMap.get(dataGridControl.value._virtualColumnsUid);
    }
}